<template>
  <div class="content">
    <div
      class="row"
      style="margin-top: 10px; background: linear-gradient(#1d1d1d, black)"
      :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
    >
      <section class="text-center col-lg-8 col-sm-12">
        <div class="header text-center" style="margin-bottom: 10px">


          <HeadImage :image="mainImage" title="TULUM MX ● NEW YEAR "
                     alt="tulum event calendar"
                     align="text-center"
                     clamp="true"
                     caption="DANCE TO CELEBRATE"/>


          <p>

            Starting December ending March
            , tons of parties and events with the best djs and producers takes place in the most magnificent Tulum,
            Mexico locations.
            With a shared goal in mind.

          </p>

          <br>
          <card class="card text-center">
            <template slot="header">

              <div
                style="
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                max-width: 500px;
              "
              >
                <div>
                  <h5
                    class="tulum-party-title"
                    style="font-size: 1.2rem; color: #2eafb4"
                  >
                    DANCE TO CELEBRATE

                  </h5>
                  <div style=" margin-top: -15px;">
                    <p
                      class="tulum-party-title"
                      style="
                    font-size: 0.8rem;

                    color: #2eafb4;
                  "
                    >NYE 2024 - 2025 SEASON</p>
                    <strong style="color: #2eafb4">Tulum, Mexico</strong>
                  </div>


                </div>
              </div>
              <div
                class="card"
                style="
                  z-index: 1;
                  max-width: 500px;
                  margin-left: auto;
                  margin-right: auto;
                  left: 0;
                  right: 0;
                "
              >


                <div style="margin: 30px" class="text-center centered">


                  <div class="text-center centered">
                    <MiniCarrousel folder="festival-nye" num-photos="3"
                                   title="The largest musical celebration for New Year happens at Tulum Mexico"></MiniCarrousel>

                  </div>

                </div>

                <div class="card" style="display: block">

                  The Best
                  <router-link :to="{path: '/brands'}">event producers</router-link>
                  ,
                  <router-link :to="{path: '/tulum-dj-schedule'}">electronic music artists</router-link>
                  ,
                  <router-link :to="{path: '/tulum-clubs'}">clubs</router-link>
                  & <a href="https://instagram.com/tulum.party" target="_blank"
                >promoters </a
                >meet in a single place to produce world class shows for a
                  world-class audience.
                </div>


              </div>


            </template>
          </card>
        </div>

        <div class="row">
          <div
            class="card text-center"
            style="margin: 40px; background-color: #171818"
          >
            <h3
              class="title tulum-party-title"
              style="margin-bottom: 0; margin-top: -40px; font-size: 32px"
              id="event-line"
            >
              Event line
            </h3>
            <h4
              class="tulum-party-subtitle"
              style="color: black !important; background-color: #2eafb4"
            >
              NYE - HIGH SEASON 2024/2025
            </h4>

            <!--          <img v-lazy="thumbnailEye+'?w=200&h=100&fit=clamp'" width="200px" height="100px"-->
            <!--               @click="changeView"-->
            <!--               alt="search parties"-->
            <!--               class="card-animation-on-hover"-->
            <!--               style="border-radius:90px;z-index: 1;margin-left: auto;margin-right: auto;left: 0;right: 0;margin-top: -40px;max-width: 350px;background-color: black; padding: 15px;">-->

            <div class="form-group form-control">
              <div
                class="input-group input-group-lg"
                style="
                max-width: 300px;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                margin-top: 2px;
              "
              >
                <input
                  type="text"
                  class="form-control"
                  aria-describedby="sizing-addon1"
                  name="search_nye"
                  autocomplete="false"
                  v-model.lazy="searchTerm"
                  v-on:change="filterParties"
                  placeholder="Search ..."
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-lg-10 offset-lg-1">

            <div class="btn-group-md" style="background-color: #171818">

              <a
                @click="changeView('detailed')"
                class="btn btn-info"
                :class="this.view === 'detailed' && 'active'"
              >
                <i class="tim tim-icons icon-single-copy-04"></i>
                <br>
                <small>DETAIL</small>
              </a>
              <a
                @click="changeView('compact')"
                class="btn btn-info"
                :class="this.view === 'compact' && 'active'"
              >
                <i class="tim tim-icons icon-bullet-list-67"></i>
                <br>
                <small>LIST</small>
              </a>

              <a
                @click="changeView('artist')"
                class="btn btn-info d-xl-none"
                :class="this.view === 'artist' && 'active'"
              >
                <i class="tim tim-icons icon-badge"></i>
                <br>
                <small>DJ SCHEDULE</small>
              </a>

              <br/>

              <br/>
              <p>
                <del>LINKTREE</del>&nbsp;| CENOTREE
              </p>
              <p><strong>MAX DEPTH </strong></p>
              <pre>UNKNOWN</pre>


            </div>


            <!--          style="background: linear-gradient(45deg, black, #2eafb4, #3cc4a6)"-->

            <div
              class="text-center"
              style="background: linear-gradient(45deg, black, #2eafb4, black, #2eafb4)"
            >
              <h4 class="text-left section-header">
                <img
                  v-lazy="this.mainImage + '?w=40w=40&fit=clamp'"
                  height="20px"
                  width="20px"
                  alt="An Eye"
                />
                &nbsp;EVENT LINE
              </h4>
              <h4 v-if="searchTerm && festivalParties.length === 0">
                No match for your query: {{ searchTerm }}
              </h4>
              <p
                v-if="searchTerm"
                style="font-size: 14px;margin-top: 10px;cursor: pointer"
                class="badge badge-dark"
                @click="
                searchTerm = null;
                filterParties();
              "
              >
                <span>{{ festivalParties.length }} events found</span> <br><br>

                <span style="color: #4BA663;">x</span> Filter:
                {{ searchTerm }}

              </p>
              <h4 v-if="view === 'artist'">DJ Schedule</h4>

              <time-line v-if="view === 'detailed'">
                <time-line-item
                  :full="true"
                  v-for="(event, idx) in festivalParties"
                  :key="event._id"
                  :inverted="idx % 2 === 0"
                  :badge-type="badgeType(idx)"
                >
                  <div slot="content">
                  <pre style="background-color: #0e0e0e; opacity: 0.3"
                  >{{ idx + 1 }} mts</pre
                  >

                    <EventCard
                      :with-lineup="false"
                      v-bind:event="event"
                      :key="event._id"
                      :idx="idx"
                    ></EventCard>

                  </div>
                </time-line-item>
              </time-line>
              <div v-if="view === 'compact'">
                <mini-event-card
                  :event="event"
                  :key="event.slug"
                  v-for="(event) in festivalParties"
                ></mini-event-card>
              </div>
              <div v-if="view === 'artist'">
                <DjScheduleCollection :desktop="true" :nye="true"></DjScheduleCollection>
              </div>
              <br/>
              <br/>
            </div>

            <section style="margin-top: 35px" class="card row"
                     :style="{ background: `linear-gradient(${this.secondaryColor}, black)` }"
            >
              <br>


              <h4 class="text-left section-header">
                <img
                  v-lazy="this.mainImage + '?w=40w=40&fit=clamp'"
                  height="20px"
                  width="20px"
                  alt="AN Eye"
                />
                &nbsp;MEMORIES
              </h4>
              <hr
                style="height: 1px; margin-top: -10px"
                :style="{ backgroundColor: this.secondaryColor }"
              />
              <HighLightCard></HighLightCard>
            </section>
          </div>
        </div>
      </section>
      <aside class="card col-4 col-lg-4 d-none d-lg-inline">
        <div class="row">


          <SmartMenu :links="links" :logo="astroImg" top-section-name="TALK TO US" cta-text="CONTACT US"
                     :primary-color="secondaryColor">

            <div class="card">

              <p>
                If you've never come to Tulum, you should try it on New Year's Eve, and if you've finally pulled the
                trigger, we want to make sure you know what to do once you get here. </p>
            </div>

            <p>Got a question, big or small?
              We’re here to answer your call.<br><br></p>
          </SmartMenu>
          <section style="margin-top: 35px" class="card row"
                   :style="{ background: `linear-gradient(${this.secondaryColor}, black)` }"
          >
            <h4 class="text-left section-header">
              <img
                v-lazy="this.mainImage + '?w=40w=40&fit=clamp'"
                height="20px"
                width="20px"
                alt="An Eye"
              />
              &nbsp;DJ SCHEDULE
            </h4>
            <hr
              style="height: 1px; margin-top: -10px"
              :style="{ backgroundColor: this.secondaryColor }">


            <small style="padding: 20px">
              <router-link :to="{path: '/tulum-dj-schedule'}">Check The Full Dj Schedule Here</router-link>
            </small>

            <DjScheduleCollection nye="true" desktop="true"></DjScheduleCollection>
          </section>

          <section style="margin-top: 35px" class="card row"
                   :style="{ background: `linear-gradient(${this.secondaryColor}, black)` }"
          >
            <h4 class="text-left section-header">
              <img
                v-lazy="this.mainImage + '?w=40w=40&fit=clamp'"
                height="20px"
                width="20px"
                alt="AN Eye"
              />
              PRODUCTION BRANDS
            </h4>
            <BrandCollection></BrandCollection>
          </section>
        </div>
      </aside>
    </div>
  </div>
</template>

<script>
import {TimeLine, TimeLineItem} from 'src/components';
import EventCard from '../../components/EventCard/EventCard';
import moment from 'moment';
import HeadImage from "@/components/HeadImage/HeadImage";
// import MobileDjCollection from "@/components/Cards/MobileDjCollection";
import DjScheduleCollection from '@/components/Cards/DjScheduleCollection';
import MiniEventCard from '@/components/EventCard/MiniEventCard';
import BrandCollection from "@/components/Cards/BrandCollection";
import HighLightCard from "@/components/Cards/HighLightCard";
import MiniCarrousel from "@/components/MiniCarrousel";
import SmartMenu from "@/components/SmartMenu/SmartMenu";

export default {
  name: 'TulumNye2024',
  created: function () {
    this.$store.dispatch('getFestival', {slug: this.slug});
    this.$store.dispatch('getLinks', {slug: 'tulum-new-year-festival'});

  },

  props: ['slug'],
  metaInfo() {
    return {
      title: 'Tulum NYE 2024/2025 · New Year Festival · Event Line',
      link: [
        {
          rel: 'canonical',
          href: 'https://www.tulum.party/tulum-new-year-festival-2024',
        },
      ],
      meta: [
        {
          name: 'description',
          content: `Tulum NYE 2024/2025. Information, Tickets & RSVP.`,
        },
        {
          name: 'keywords',
          content: `Tulum Festival 2024 2025 Rave DJ Parties`,
        },
        {
          property: 'og:title',
          content: `Tulum NYE 2024/2025. Tickets & Information`,
        },
        {
          property: 'og:url',
          content: `https://tulum.party/tulum-new-year-festival-2024`,
        },
        {
          property: 'og:description',
          content: `Tulum NYE 2024/2025. Events @ Tulum Mexico`,
        },
        {property: 'og:type', content: 'article'},
        {
          property: 'og:image',
          content:
            'https://imgix.cosmicjs.com/de804f80-8ec0-11ee-b953-fbe0f06ed1b1-image.png',
        },
      ],
    };
  },
  data: () => {
    return {
      view: 'detailed',
      // festivalParties: [],
      highlightStartIdx: 0,
      searchTerm: null,
      primaryColor: '#2eafb4',
      secondaryColor: '#1d1d1d',
      palmeras:
        'https://imgix.cosmicjs.com/d215fe40-8144-11ee-9ebe-eb22b0517bf0-IMG0515.PNG',
      tulumpartyLogo:
        'https://imgix.cosmicjs.com/afd1db60-3abd-11ee-b850-2d3046e40f87-poster.png',
      insta:
        'https://imgix.cosmicjs.com/6d099b90-5c09-11ec-a8a3-53f360c99be6-instagram.png',
      astroImg:
        'https://imgix.cosmicjs.com/6ed6b0f0-60cd-11ee-b3cd-9debaa756aee-image.png',
      bgPalm:
        'https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&auto=format',
      buzosImage:
        'https://imgix.cosmicjs.com/15d07430-9a09-11ee-bdf1-7fea048bb7c9-image.png',
      mainImage:
        'https://imgix.cosmicjs.com/d38e0f20-9a39-11ee-b34c-99d1d89e42eb-aqua-eye.png',
      thumbnailEye:
        ' https://imgix.cosmicjs.com/233cbf00-8ec1-11ee-b953-fbe0f06ed1b1-image.png',
      marcianitos:
        'https://imgix.cosmicjs.com/dbcd2d20-afde-11ec-97bc-19d12908cbbe-image.png',
    };
  },
  components: {
    SmartMenu,
    MiniCarrousel,
    HighLightCard,
    MiniEventCard,
    DjScheduleCollection,
    TimeLine,
    TimeLineItem,
    EventCard,
    HeadImage,
    BrandCollection,
  },
  // watch:{
  //   search(a) {
  //     console.log(a);
  //   }
  // },
  methods: {
    notifyChange: function notifyChange(change) {
      this.$notify({
        type: 'primary',
        title: 'Event Line - NYE 2024',
        message: `${change}`,
        icon: 'tim-icons icon-bell-55',
      });
    },
    filterParties: function filterParties() {
      if (this.searchTerm) {
        if (this.searchTerm.length > 4) {
          this.$rollbar.info('NYE SEARCH', {keyword: this.searchTerm});
        }
        this.notifyChange('Showing Results For: ' + this.searchTerm);
        this.changeView('compact');
      }
      return [...this.$store.state.festival.metadata.parties]
        .sort((a, b) => {
          let time_a = a.metadata.start_time || '00:00';
          let time_b = b.metadata.start_time || '00:00';
          return moment.utc(a.metadata.date + 'T' + time_a) >
          moment.utc(b.metadata.date + 'T' + time_b)
            ? 1
            : -1;
        })
        .filter((item) => {
          return this.searchTerm
            ? item.slug.match(this.searchTerm.toLowerCase()) ||
            item.title.toLowerCase().match(this.searchTerm.toLowerCase()) ||
            item.metadata.lineup
              .toLowerCase()
              .match(this.searchTerm.toLowerCase())
            : true;
        });
    },
    goToParty(party) {
      this.$router.push({path: '/event/' + party.target.value});
      this.$rollbar.info('NYE - QUICK PARTY: ' + party.target.value);
    },
    changeView(state) {
      this.view = state;
      // this.notifyChange('View:' + state);
    },
    formatDate(date) {
      return moment(date).format(' DD MMM ');
    },
    badgeType(idx) {
      return ['primary', 'warning', 'info', 'danger'][idx % 4];
    },
    onQuickReserveClick() {
      window.ga(
        'send',
        'event',
        'reservations',
        'NYE Info Request',
        'Main',
        30
      );
      this.$rollbar.info('NYE Info Request');
    },
    badgeIcon(idx) {
      return 'tim-icons icon-sound-wave';
    },
  },
  computed: {
    links: {
      get() {
        return (
          this.$store.state.links || []
        );
      },
    },
    festivalParties: {
      get() {
        // if (this.searchTerm) {
        //   if (this.searchTerm.length > 4) {
        //     this.$rollbar.info('NYE SEARCH', { keyword: this.searchTerm });
        //   }
        //   this.notifyChange('Showing Results For: ' + this.searchTerm);
        //   this.changeView('compact');
        // }
        return [...this.$store.state.festival.metadata.parties]
          .sort((a, b) => {
            let time_a = a.metadata.start_time || '00:00';
            let time_b = b.metadata.start_time || '00:00';
            return moment.utc(a.metadata.date + 'T' + time_a) >
            moment.utc(b.metadata.date + 'T' + time_b)
              ? 1
              : -1;
          })
          .filter((item) => {
            let partyDateEndOfDay = moment.utc(item.metadata.date).endOf('day');
            let now = moment().startOf('day');
            return partyDateEndOfDay >= now;
          })
          .filter((item) => {
            return this.searchTerm
              ? item.slug.match(this.searchTerm.toLowerCase()) ||
              item.title
                .toLowerCase()
                .match(this.searchTerm.toLowerCase()) ||
              item.metadata.lineup
                .toLowerCase()
                .match(this.searchTerm.toLowerCase())
              : true;
          });
      },
    },
  },
};
</script>
